/* eslint-disable */
import axios from 'axios'

const VUE_APP_URL_API = process.env.VUE_APP_URL_API
export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async EXECUTE({ commit }, requestOption) {
      requestOption.url = VUE_APP_URL_API + requestOption.url
      const respdata = await axios(requestOption).then(res => res.data).catch(e => e.response.data)
      return respdata
    },
  },
}
