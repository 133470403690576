import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/middleware/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'facturador',
      component: () => import('@/views/Home.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/reporte',
      name: 'reporte',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/usuario',
      name: 'usuario',
      component: () => import('@/views/usuario/lista/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/usuario/agregar',
      name: 'usuario-agregar',
      component: () => import('@/views/usuario/agregar/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/usuario/editar/:id',
      name: 'usuario-editar',
      component: () => import('@/views/usuario/editar/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/usuario/password',
      name: 'usuario-password',
      component: () => import('@/views/usuario/password/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/serie',
      name: 'serie',
      component: () => import('@/views/serie/lista/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/serie/agregar',
      name: 'serie-agregar',
      component: () => import('@/views/serie/agregar/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/serie/editar/:id',
      name: 'serie-editar',
      component: () => import('@/views/serie/editar/index.vue'),
      meta: {
        middleware: auth,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index || 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }
  return next()
})

export default router
